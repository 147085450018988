import { HTTP } from '../../axios/axios'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/vacancies', { headers: { 'accept-language': lang } })
    data = res.data
    return data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (vacancy, lang) {
  vacancy.lang = lang
  return await HTTP.post('/vacancies', vacancy)
}
async function update (vacancy, id) {
  return await HTTP.patch('/vacancies/' + id, vacancy)
}
async function deleteOne (vacancy) {
  return await HTTP.delete('/vacancies/' + vacancy._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/vacancies/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
